import React, { useEffect } from 'react';

const Modal = ({ isOpen, onClose, content }) => {
  useEffect(() => {
    if (isOpen) {
      // Lock body scroll
      document.body.classList.add('lock-scroll');
    } else {
      // Unlock body scroll
      document.body.classList.remove('lock-scroll');
    }

    // Cleanup function to re-enable scrolling when the modal is closed
    return () => {
      document.body.classList.remove('lock-scroll');
    };
  }, [isOpen]);

  // Prevent closing the modal when clicking inside the modal content
  const handleModalClick = (e) => {
    e.stopPropagation(); // Prevent click events from bubbling up
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose} // Clicking outside the modal closes it
    >
      {/* Modal content container with high z-index */}
      <div
        className="bg-white p-8 rounded-lg w-10/12 sm:w-9/12 md:w-3/4 lg:w-1/2 max-h-[90vh] overflow-y-auto relative z-60"
        onClick={handleModalClick} // Prevent closing when clicking inside the modal
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 text-xl"
        >
          &times;
        </button>
        {/* Modal content */}
        <div>{content}</div>
      </div>
    </div>
  );
};

export default Modal;
