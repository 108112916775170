import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../logo.png';  
import rotatingrays from '../rotating-rays.png';  
import GooglePlayButton from './GooglePlayButton';
import AppleButton from './AppleButton';
import watermark from '../assets/images/background_loop.png';
import Modal from './Modal';  // Import the Modal component

function Home() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
    setSuccess('');
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError(t('invalid_email'));
      return;
    }
    
    if (!termsAccepted) {
      setError('You must accept the terms and conditions');
      return;
    }
   
    const mailEndpoint = `https://giftergo.api.blarogames.com/subscribe`;

    try {
      const response = await fetch(mailEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email_address: email,
          language: i18n.language,
          status: 'subscribed',
        }),
      });

      if (response.ok) {
        setSuccess(t('subscription_success'));
        setEmail('');
      } else {
        setError(t('subscription_failure'));
      }
    } catch (error) {
      setError(t('subscription_failure'));
    }
  };

  // Modal functions
  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  return (
    <section 
      className="bg-cyan-400 py-8 flex flex-col items-center"
      style={{
        backgroundImage: `url(${watermark})`,
        backgroundRepeat: 'repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        opacity: 1 
      }}
    >
      <div className="h-32"></div>
      <div className="relative mb-8" style={{ width: '334px', height: '230px' }}>
        <img 
          src={rotatingrays} 
          alt="Rotating" 
          className="absolute inset-0 animate-spin-slow w-full h-full max-w-xs md:max-w-full" 
          style={{ width: '400px', height: '400px', top: '-75px' }}
        />
        <img src={logo} alt="Logo" className="absolute inset-0 w-full h-full max-w-xs md:max-w-full" />
      </div>
      <div className='h-16'></div>
      {/* Updated container width */}
      <div className="container mx-auto max-w-screen-lg grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 px-4 w-full">
        <div className="flex justify-center">
          <GooglePlayButton />
        </div>
        <div className="flex justify-center">
          <AppleButton />
        </div>
      </div>
      <div className='h-8'></div>

      {/* Updated container width */}
      <div className="container mx-auto max-w-screen-lg bg-white bg-opacity-75 p-6 rounded-lg shadow-md text-center mb-8 w-full">
        <h2 className="text-3xl font-bold mb-8 mt-4">{t('complete_your__album')}</h2>
        <p className="mb-4">{t('complete_your__album_description')}</p>
        <p className="mb-4">{t('complete_your__album_description_2')}</p>
      </div>

      {/* Updated container width */}
      <div className="container mx-auto max-w-screen-lg bg-white p-6 rounded-lg shadow-md text-center mb-8 w-full">
        <h2 className="text-3xl font-bold mb-8 mt-4">{t('register_message')}</h2>
        <form className="flex flex-col mb-8 md:flex-row items-center" onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder={t('email_placeholder')}
            className="px-4 py-2 border rounded-t-lg md:rounded-l-lg md:rounded-t-none w-full mb-4 md:mb-0 md:mr-2"
            required
          />
          <div className="flex items-center mb-4 md:mb-0 md:mr-2">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={handleTermsChange}
              className="mr-2"
              required
            />
            <label>{t('terms_read')}</label>
          </div>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-b-lg md:rounded-r-lg md:rounded-b-none w-full md:w-auto">
            {t('register_button')}
          </button>
        </form>
        <div className="flex items-center m-4 gap-8 mb-4 md:mb-0 md:mr-2">
          <button 
            onClick={() => openModal(<iframe src="https://www.blarogames.com/PrivacyPolicy.html" className="w-full h-[80vh] max-w-full" title="Privacy Policy"></iframe>)} 
            className="text-blue-500 mb-1"
          >
            {t('Privacy Policy')}
          </button>
          <button 
            onClick={() => openModal(<iframe src="https://www.blarogames.com/TermsAndConditions.html" className="w-full h-[80vh] max-w-full" title="Terms and Conditions"></iframe>)} 
            className="text-blue-500"
          >
            {t('terms_and_conditions')}
          </button>
        </div>

        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}

        {/* Modal Component */}
        <Modal isOpen={isModalOpen} onClose={closeModal} content={modalContent} />
      </div>

      {/* Updated container width */}
      <div className="container mx-auto max-w-screen-lg grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
        <div className="md:col-span-1 grid grid-cols-1 gap-4">
          <div className="md:col-span-1 grid grid-cols-1 gap-4">
            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
              <div className="md:col-span-1 grid grid-cols-1 gap-4">
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="flex justify-center items-center">
                    <img src="/images/aggiorna.png" alt="Card 1" className="w-2/3 h-auto rounded-lg m-2" />
                  </div>
                  <div className="space-y-4">
                    <h3 className="text-xl font-bold mb-2">{t('update_automated')}</h3>
                    <p className="mb-4">{t('update_automated_description')}</p>
                  </div>
                </div>
              </div>              
            </div>
          </div>           
        </div>
        <div className="md:col-span-1 grid grid-cols-1 gap-4">
          <div className="md:col-span-1 grid grid-cols-1 gap-4">
            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
              <div className="md:col-span-1 grid grid-cols-1 gap-4">
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="space-y-4">                    
                    <h3 className="text-xl font-bold mb-2">{t('algorythm')}</h3>
                    <p className="mb-4">{t('algorythm_description')}</p>
                  </div>
                  <div className="flex justify-center items-center">
                    <img src="/images/algoritmo.png" alt="Card 1" className="w-2/3 h-auto rounded-lg m-2" />
                  </div>
                </div>
              </div>
            </div>         
          </div>       
        </div>
      </div>

      {/* Updated container width */}
      <div className="container mx-auto max-w-screen-lg grid grid-cols-1 md:grid-cols-3 gap-4 pt-4 w-full">
        <div className="md:col-span-2 grid grid-cols-1 gap-4">
          <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
            <div className="md:col-span-2 grid grid-cols-1 gap-4">
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="space-y-4">
                  <h3 className="text-xl font-bold mb-2">{t('gifter_go_game')}</h3>
                  <p className="mb-4">{t('gifter_go_game_description')}</p>
                </div>
                <div className="flex justify-center items-center">
                  <img src="/images/Divertiti.png" alt="Card 1" className="w-2/3 h-auto rounded-lg m-2" />
                </div>
              </div>
            </div>           
          </div>
        </div>
        <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-2">{t('secured_trades')}</h3>
          <p className="mb-4">{t('secured_trades_description')}</p>
          <div className="flex justify-center items-center">
            <img src="/images/scambi.png" alt="Card 1" className="w-2/5 h-auto rounded-lg" />
          </div>
        </div>
      </div>

      {/* Updated container width */}
      <div className="container mx-auto max-w-screen-lg grid grid-cols-5 md:grid-cols-5 gap-4 pt-4 w-full">
        <div className="md:col-span-1 grid grid-cols-1 gap-4"></div>
        <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md col-span-3 grid grid-cols-1 md:col-start-2 md:col-span-3">
          <div className="flex flex-col items-center justify-center space-y-4">
            <h3 className="text-xl font-bold mb-2">{t('multilanguage')}</h3>
            <p className="mb-4">{t('multilanguage_description')}</p>
            <div className="flex justify-center items-center w-full">
              <img src="/images/multilingua.png" alt="Card 1" className="w-full md:w-1/2 h-auto rounded-lg m-2" />
            </div>
          </div>
        </div>
      </div>

      <div className='h-16'></div>
    </section>
  );
}

export default Home;
