

import React from 'react';

const NotFound = () => {  

  return (
    <section className="bg-cyan-400 min-h-screen flex flex-col justify-center items-center">        
        <div className="w-3/4 h-48 bg-white bg-opacity-75 p-6 rounded-lg shadow-md flex justify-center items-center">
            <div>
                <h1>404 - Page Not Found</h1>
                <p>The page you are looking for does not exist.</p>
            </div>
        </div>
    </section>
);
};

export default NotFound;
