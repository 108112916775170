import React from 'react';
import { useTranslation } from 'react-i18next';
import appleIcon from '../appleicon.png'; // Assicurati di usare il percorso corretto per l'icona

function AppleButton() {
  const { t } = useTranslation();

  return (
    <a
      href="https://apps.apple.com/app/gifter-go/id6547852468"
      className="flex items-center bg-black text-white py-2 px-4 rounded-lg w-96"
      style={{ maxWidth: '270px' }}
    >
      <img src={appleIcon} alt="Apple Icon" className="w-16 h-16 mr-8" />
      <div className="text-left">
        <p className="text-s">{t('available_on')}</p>
        <p className="text-2xl font-bold">{t('app_store')}</p>
      </div>
    </a>
  );
}

export default AppleButton;
