import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // detection: {
    //   order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
    //   caches: ['localStorage', 'cookie']
    // },
    detection: {
      order: ['navigator', 'htmlTag', 'localStorage', 'cookie', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie']
    },    
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false
    },
  });

  // Set the language to English if it's not already set
if (!i18n.language || i18n.language === 'it') {
  i18n.changeLanguage('en');
}

export default i18n;
