import React from 'react';
import { useTranslation } from 'react-i18next';
import googlePlayIcon from '../google-play-logo-icon-png-svg.png'; // Assicurati di usare il percorso corretto per l'icona

function GooglePlayButton() {
  const { t } = useTranslation();

  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.Blaro.GifterGO"
      className="flex items-center bg-black text-white py-2 px-4 rounded-lg w-96"
      style={{ maxWidth: '270px' }}
    >
      <img src={googlePlayIcon} alt="Google Play Icon" className="w-16 h-16 mr-8" />
      <div className="text-left">
        <p className="text-s">{t('available_on')}</p>
        <p className="text-2xl font-bold">{t('google_play')}</p>
      </div>
    </a>
  );
}

export default GooglePlayButton;
