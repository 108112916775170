import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GB, IT, ES, DE, FR, PT } from 'country-flag-icons/react/3x2';

const languages = [
  { code: 'it', name: 'Italiano', icon: IT },
  { code: 'en', name: 'English', icon: GB },
  { code: 'es', name: 'Español', icon: ES },
  { code: 'de', name: 'Deutsch', icon: DE },
  { code: 'fr', name: 'Français', icon: FR },
  { code: 'pt', name: 'Português', icon: PT },
];

function Navbar() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav className="bg-yellow-400 p-4 w-full fixed top-0 border-2 border-black z-30"> {/* Set z-index to 30 */}
      <div className="container max-w-screen-lg mx-auto flex flex-row justify-between items-center overflow-hidden">
        <div className="flex items-center">
          <select
            onChange={(e) => handleLanguageChange(e.target.value)}
            className="bg-white text-black font-bold border-none outline-none p-2 rounded-md"
            defaultValue={i18n.language}
          >
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>
        <ul className="flex space-x-4 items-center overflow-hidden">
          <li>
            <Link to="/" className="text-black font-bold">Home</Link>
          </li>
          <li>
            <Link to="/faq" className="text-black font-bold">FAQ</Link>
          </li>
          <li>
            <Link to="/tutorial" className="text-black font-bold">Tutorial</Link>
          </li>
          <li>
            <Link to="/contacts" className="text-black font-bold">{t('CONTACTS')}</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
