import React from 'react';
import { useTranslation } from 'react-i18next';
import watermark from '../assets/images/background_stelle.png';

function TutorialPage() {
    const { t } = useTranslation();

  return (
    <section 
    className="bg-cyan-400 py-8 flex flex-col items-center"
    style={{
        backgroundImage: `url(${watermark})`,
      backgroundRepeat: 'repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      opacity: 1 
    }}>
        <div className='h-32'></div>
        <h1 className="text-center text-4xl font-bold mb-8">TUTORIAL</h1>
        <div className="container mx-auto grid grid-cols-1 s gap-4 px-4 w-full md:w-1/2 ">

            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-3 md:col-span-1 flex justify-center items-center">
                        <img src={t('TUTORIAL-COLLECTION-IMG-1')} alt={t('TUTORIAL-COLLECTION-IMG-1')} className="w-4/5 h-auto rounded-lg" />
                    </div>
                    <div className="col-span-3 md:col-span-2 space-y-4">
                        <h3 className="text-xl font-bold mb-2">{t('TUTORIAL-COLLECTION-1')}</h3>
                        <p className="mb-4">{t('TUTORIAL-COLLECTION-2')}</p>
                        <h3 className="text-xl font-bold mb-2">{t('TUTORIAL-COLLECTION-3')}</h3>
                        <p className="mb-4">{t('TUTORIAL-COLLECTION-4')}</p>
                        <h3 className="text-xl font-bold mb-2">{t('TUTORIAL-COLLECTION-5')}</h3>
                        <p className="mb-4">{t('TUTORIAL-COLLECTION-6')}</p>
                        <h3 className="text-xl font-bold mb-2">{t('TUTORIAL-COLLECTION-7')}</h3>
                        <p className="mb-4">{t('TUTORIAL-COLLECTION-8')}</p>
                    </div>
                </div>
            </div>
            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-3 md:col-span-1 flex justify-center items-center">
                                <img src={t('TUTORIAL-SINGLE-SET-IMG-1')} alt={t('TUTORIAL-SINGLE-SET-IMG-1')} className="w-4/5 h-auto rounded-lg" />
                            </div>
                            <div className="col-span-3 md:col-span-2 space-y-4">
                                <p className="mb-4">{t('TUTORIAL-SINGLE-SET-1')}</p>
                                <p className="mb-4">{t('TUTORIAL-SINGLE-SET-2')}</p>
                                <p className="mb-4"><img src="images/PictoIcon_Arrow_Down.png" alt="Card 1" className="w-8 h-8" />{t('TUTORIAL-SINGLE-SET-3')}</p>
                                <p className="mb-4"><img src="images/PictoIcon_Arrow_Up.png" alt="Card 1" className="w-8 h-8" />{t('TUTORIAL-SINGLE-SET-4')}</p>
                                <p className="mb-4"><img src="images/IconChat.png" alt="Card 1" className="w-8 h-8" />{t('TUTORIAL-SINGLE-SET-5')}</p>
                                <p className="mb-4">{t('TUTORIAL-SINGLE-SET-6')}</p>
                                <p className="mb-4">{t('TUTORIAL-SINGLE-SET-7')}</p>
                            </div>
                        </div>
                    
                </div>
                <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-3 md:col-span-1 flex justify-center items-center">
                            <img src={t('TUTORIAL-PROPOSAL-LIST-IMG-1')} alt={t('TUTORIAL-PROPOSAL-LIST-IMG-1')} className="w-4/5 h-auto rounded-lg" />
                            </div>
                            <div className="col-span-3 md:col-span-2 space-y-4">
                            <h3 className="text-xl font-bold mb-2">{t('TUTORIAL-PROPOSAL-LIST-1')}</h3>
                            <p className="mb-4">{t('TUTORIAL-PROPOSAL-LIST-2')}</p>
                            <h3 className="text-xl font-bold mb-2">{t('TUTORIAL-PROPOSAL-LIST-3')}</h3>
                            <p className="mb-4">{t('TUTORIAL-PROPOSAL-LIST-4')}</p>
                            <h3 className="text-xl font-bold mb-2">{t('TUTORIAL-PROPOSAL-LIST-5')}</h3>
                            <p className="mb-4">{t('TUTORIAL-PROPOSAL-LIST-6')}</p>
                            <h3 className="text-xl font-bold mb-2">{t('TUTORIAL-PROPOSAL-LIST-7')}</h3>
                            <p className="mb-4">{t('TUTORIAL-PROPOSAL-LIST-8')}</p>
                            </div>
                        </div>
                    
                </div>                    
                

            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-3 md:col-span-1 flex justify-center items-center">
                        <img src={t('TUTORIAL-SEARCH-IMG-1')} alt={t('TUTORIAL-SEARCH-IMG-1')} className="w-4/5 h-auto rounded-lg" />
                    </div>
                    <div className="col-span-3 md:col-span-2 space-y-4">
                        <p className="mb-4">{t('TUTORIAL-SEARCH-1')}</p>
                        <p className="mb-4">{t('TUTORIAL-SEARCH-2')}</p>
                        <p className="mb-4">{t('TUTORIAL-SEARCH-3')}</p>
                    </div>
                </div>
            </div>
                       
                           

                    <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-3 md:col-span-1 flex justify-center items-center">
                            <img src={t('TUTORIAL-DETAIL-IMG-1')} alt={t('TUTORIAL-DETAIL-IMG-1')} className="w-4/5 h-auto rounded-lg" />
                            </div>
                            <div className="col-span-3 md:col-span-2 space-y-4">
                             <h3 className="text-xl font-bold mb-4">{t('TUTORIAL-DETAIL-1')}</h3>
                            <p className="mb-4">{t('TUTORIAL-DETAIL-2')}</p>
                            <h3 className="text-xl font-bold mb-4">{t('TUTORIAL-DETAIL-3')}</h3>
                            <p className="mb-4">{t('TUTORIAL-DETAIL-4')}</p>
                            <h3 className="text-xl font-bold mb-2">{t('TUTORIAL-DETAIL-5')}</h3>
                            <p className="mb-4">{t('TUTORIAL-DETAIL-6')}</p>
                            </div>
                        </div>
                    
            

            </div>
        </div>
        <div className='h-32'></div>
    </section>
  );
}

export default TutorialPage;