import React from 'react';

function Footer() {
  return (
    <footer className="bg-yellow-400 py-4 w-full fixed bottom-0 border-2 border-black z-30"> {/* Set z-index to 30 */}
      <div className="container mx-auto text-center text-white w-3/4 md:w-full">
        <p className="text-black font-bold">
          &copy; 2024 GifterGO. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
