import React from 'react';
import watermark from '../assets/images/background_loop.png';
import { useTranslation } from 'react-i18next';

function ContactPage() {
    const { t } = useTranslation();

    const email = "support@blarogames.com"; 

    return (
        <section className="bg-cyan-400 min-h-screen flex flex-col justify-center items-center"
        style={{
            backgroundImage: `url(${watermark})`,
          backgroundRepeat: 'repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          opacity: 1 
        }}>

            <h1 className="text-center text-4xl font-bold mb-8">{t('CONTACTS')}</h1>
            <div className="w-3/4 h-48 bg-white bg-opacity-75 p-6 rounded-lg shadow-md flex justify-center items-center">
                <a 
                    href={`mailto:${email}`} 
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                >
                    {t('MAIL-US')}
                </a>
            </div>
        </section>
    );
}

export default ContactPage;
