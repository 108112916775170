import React from 'react';
import { useTranslation } from 'react-i18next';
import watermark from '../assets/images/background_loop.png';

function FaqPage() {
    const { t } = useTranslation();

  return (
    <section 
    className="bg-cyan-400 py-8 flex flex-col items-center"
    style={{
        backgroundImage: `url(${watermark})`,
      backgroundRepeat: 'repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      opacity: 1 
    }}>
        <div className='h-32'></div>
        <h1 className="text-center text-4xl font-bold mb-8">FAQ</h1>
        <div className="container mx-auto grid grid-cols-1 s gap-4 px-4 w-full md:w-1/2 ">

            <div className="md:col-span-2 grid grid-cols-1 gap-4">

            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="md:col-span-2 grid grid-cols-1 gap-4">                            
                    <div className="space-y-4">
                        <h3 className="text-xl font-bold mb-4">{t('faq-1')}</h3>
                        <p className="mb-4">{t('faq-2')}</p>                            
                    </div>                        
                </div>
            </div>

            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="md:col-span-2 grid grid-cols-1 gap-4">                            
                    <div className="space-y-4">
                        <h3 className="text-xl font-bold mb-4">{t('faq-3')}</h3>
                        <p className="mb-4">{t('faq-4')}</p>                            
                    </div>                        
                </div>
            </div>
            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="md:col-span-2 grid grid-cols-1 gap-4">                            
                    <div className="space-y-4">
                        <h3 className="text-xl font-bold mb-4">{t('faq-5')}</h3>
                        <p className="mb-4">{t('faq-6')}</p>                            
                    </div>                        
                </div>
            </div>
            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="md:col-span-2 grid grid-cols-1 gap-4">                            
                    <div className="space-y-4">
                        <h3 className="text-xl font-bold mb-4">{t('faq-7')}</h3>
                        <p className="mb-4">{t('faq-8')}</p>                            
                    </div>                        
                </div>
            </div>
            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="md:col-span-2 grid grid-cols-1 gap-4">                            
                    <div className="space-y-4">
                        <h3 className="text-xl font-bold mb-4">{t('faq-9')}</h3>
                        <p className="mb-4">{t('faq-10')}</p>                            
                    </div>                        
                </div>
            </div>
            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="md:col-span-2 grid grid-cols-1 gap-4">                            
                    <div className="space-y-4">
                        <h3 className="text-xl font-bold mb-4">{t('faq-11')}</h3>
                        <p className="mb-4">{t('faq-12')}</p>                            
                    </div>                        
                </div>
            </div>
            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="md:col-span-2 grid grid-cols-1 gap-4">                            
                    <div className="space-y-4">
                        <h3 className="text-xl font-bold mb-4">{t('faq-12')}</h3>
                        <p className="mb-4">{t('faq-14')}</p>                            
                    </div>                        
                </div>
            </div>
            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="md:col-span-2 grid grid-cols-1 gap-4">                            
                    <div className="space-y-4">
                        <h3 className="text-xl font-bold mb-4">{t('faq-15')}</h3>
                        <p className="mb-4">{t('faq-16')}</p>                            
                    </div>                        
                </div>
            </div>
            <div className="bg-white bg-opacity-75 p-6 rounded-lg shadow-md">
                <div className="md:col-span-2 grid grid-cols-1 gap-4">                            
                    <div className="space-y-4">
                        <h3 className="text-xl font-bold mb-4">{t('faq-17')}</h3>
                        <p className="mb-4">{t('faq-18')}</p>                            
                    </div>                        
                </div>
            </div>
            </div>
        </div>
        <div className='h-32'></div>
    </section>
  );
}

export default FaqPage;