import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import FaqPage from './Components/FaqPage';
import ContactPage from './Components/ContatPage';
import Footer from './Components/Footer';
import NotFound from './NotFound';
import TutorialPage from './Components/TutorialsPage';
import MetaPixel from './utils/meta/metaPixel'; // Import the MetaPixel component

function App() {
  return (
    <Router>
      <div className="App">
        {/* Integrate Meta Pixel to initialize it on every page load */}
        <MetaPixel />
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/contacts" element={<ContactPage />} />
          <Route path="/tutorial" element={<TutorialPage />} />
          {/* Catch-all route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Navbar />
        <Footer />
      </div>
    </Router>
  );
}

export default App;